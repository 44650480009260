import { Component, OnInit } from '@angular/core';
import {TaskManagementService} from '../../../services/task-management.service';

declare var Highcharts: any;
declare var toastr: any;

@Component({
  selector: 'app-pm-tool-dashboard',
  templateUrl: './pm-tool-dashboard.component.html',
  styleUrls: ['./pm-tool-dashboard.component.css']
})
export class PmToolDashboardComponent implements OnInit {
  liveProjects: any;
  todayTickets: any;
  liveTickets: any;
  todaySupportTickets: any;
  todayDevTickets: any;
  liveSupportTickets: any;
  liveDevTickets: any;
  employeeListData: any;
  todayEmployeeListData: any;

  constructor(
    private task: TaskManagementService,
  ) { }

  ngOnInit(): void {
    const data = {
      active: 48,   // Percentage of active tasks
      inactive: 30, // Percentage of inactive tasks
    };

    this.getTaskDetail();
    this.getTodayAndTotalTickets();
    this.getAuthorList();
  }

  getTaskDetail(): any {
    this.task.getLiveProjects().subscribe(
      (data) => {
        this.liveProjects = data;
        this.initDashboardLiveProjects(this.liveProjects);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getTodayAndTotalTickets(): any {
    this.task.getTodayAndTotalTickets().subscribe(
      (data) => {
        this.todayTickets = data.today_tickets_summary;
        this.todaySupportTickets = data.today_tickets_summary.support;
        this.todayDevTickets = data.today_tickets_summary.Dev;
        this.liveTickets = data.total_tickets_summary;
        this.liveSupportTickets = data.total_tickets_summary.support;
        this.liveDevTickets = data.total_tickets_summary.Dev;
        this.initTodaySupportChart(this.todaySupportTickets);
        this.initTodayDevChart(this.todayDevTickets);
        this.initLiveSupportChart(this.liveSupportTickets);
        this.initLiveDevChart(this.liveDevTickets);
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  getAuthorList(): any {
    this.task.getAuthorList().subscribe(
      (data) => {
        this.employeeListData = data.today_emp_task_list;
        this.todayEmployeeListData = data.emp_task_list;
      },
      (err) => {
        toastr.error(err.error.error);
      },
    );
  }

  initDashboardLiveProjects(data): any {
    const active = data.active_projects;
    const inactive = data.total_projects - data.active_projects;

    Highcharts.chart('projects-Chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: '#ffffff', // Match the card's background
        height: 130, // Adjusted height for the card
        width: 230,  // Adjusted width for the card
        spacing: [0, 0, 0, 0], // Remove spacing around the chart
        marginTop: 1, // Add margin to accommodate the title
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 3, // Set the border width to be more prominent
          // colors: [ '#0000FF', '#FF0000'], // Active and inactive task colors
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '50%',
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Tasks',
          innerSize: '50%', // Creates a donut chart
          data: [
            {
              name: 'Active Projects',
              y: active,
              color: 'blue', // Color for active tasks
            },
            {
              name: 'Inactive Projects',
              y: inactive,
              color: 'red', // Color for inactive tasks
            },
          ],
        },
      ],
      legend: {
        align: 'right', // Align the legend to the right
        verticalAlign: 'middle', // Position the legend in the middle
        layout: 'vertical', // Arrange legend items vertically
        itemStyle: {
          fontSize: '12px', // Adjust font size to fit within the card
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0, // Add some padding to separate the legend from the chart
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  initTodaySupportChart(data: any): void {
    Highcharts.chart('today-support-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: '#ffffff',
        height: 200,
        width: 250,
        spacing: [0, 0, 0, 0], // Remove spacing around the chart
        marginTop: 1, // Add margin to accommodate the title
        marginBottom: -10,  // Remove margin around the chart
      },
      title: {
        text: 'Support',
        align: 'left',
        x: 40,
        y: 20, // Adjust as needed to center the title within the chart
        style: {
          fontSize: '17px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 5,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '50%', // Adjust the innerSize to create the donut shape
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Tasks',
          innerSize: '50%', // Ensure innerSize is set here as well
          data: data.data,
        },
      ],
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  initTodayDevChart(data: any): void {
    Highcharts.chart('today-dev-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: '#ffffff',
        height: 200,
        width: 250,
        spacing: [0, 0, 0, 0], // Remove spacing around the chart
        marginTop: 1, // Add margin to accommodate the title
        marginBottom: -10,  // Remove margin around the chart
      },
      title: {
        text: 'Dev',
        align: 'left',
        x: 50,
        y: 20, // Adjust as needed to center the title within the chart
        style: {
          fontSize: '17px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 5,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '50%', // Adjust the innerSize to create the donut shape
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Tasks',
          innerSize: '50%', // Ensure innerSize is set here as well
          data: data.data,
        },
      ],
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }


  initLiveSupportChart(data): any {
    Highcharts.chart('live-support-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: '#ffffff',
        height: 200,
        width: 250,
        spacing: [0, 0, 0, 0], // Remove spacing around the chart
        marginTop: 1, // Add margin to accommodate the title
        marginBottom: -10,  // Remove margin around the chart
      },
      title: {
        text: 'Support',
        align: 'left',
        x: 40,
        y: 20, // Adjust as needed to center the title within the chart
        style: {
          fontSize: '17px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 5,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '50%',
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Tasks',
          innerSize: '50%',
          data: data.data,
        },
      ],
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

  initLiveDevChart(data): any {
    Highcharts.chart('live-dev-chart', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        backgroundColor: '#ffffff',
        height: 200,
        width: 250,
        spacing: [0, 0, 0, 0], // Remove spacing around the chart
        marginTop: 1, // Add margin to accommodate the title
        marginBottom: -10,  // Remove margin around the chart
      },
      title: {
        text: 'Dev',
        align: 'left',
        x: 50,
        y: 20, // Adjust as needed to center the title within the chart
        style: {
          fontSize: '17px',
          fontWeight: 'bold'
        }
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderWidth: 5,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
          innerSize: '50%',
        },
      },
      series: [
        {
          type: 'pie',
          name: 'Tasks',
          innerSize: '50%',
          data: data.data,
        },
      ],
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          fontSize: '12px',
          fontWeight: 'bold',
        },
        symbolHeight: 12,
        padding: 0,
        itemMarginTop: 0,
        itemMarginBottom: 0,
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
    });
  }

}
