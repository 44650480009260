<div>
  <!--begin::Card-->

  <div class="card card-custom gutter-b">
    <div
      class="card-header card-header-tabs-line notes-card-header d-print-none"
    >
      <div class="card-toolbar notes-toolbar">
        <ul class="nav nav-tabs nav-bold nav-tabs-line">
          <li
            class="nav-item notes-li"
            (click)="getProgressNotes('nursing', '')"
          >
            <a class="nav-link active" data-toggle="tab" href="#nursing">
              <span class="nav-text">Nursing</span>
            </a>
          </li>
          <li
            class="nav-item notes-li"
            (click)="getProgressNotes('doctor', '')"
          >
            <a class="nav-link" data-toggle="tab" href="#dutydoctor">
              <span class="nav-text">Duty Doctor</span>
            </a>
          </li>
          <li
            class="nav-item notes-li"
            (click)="getProgressNotes('pharmacist', '')"
          >
            <a class="nav-link" data-toggle="tab" href="#pharmacistNotes">
              <span class="nav-text">Pharmacist Notes</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="card-body user-card-body">
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="nursing"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_1_4"
        >
          <form
            *ngIf="isAdd === true"
            [formGroup]="progressNotesForm"
            (ngSubmit)="showNursingConfirmationModal()"
          >
            <div class="row m-0">
              <div class="col-12">
                <div class="form-group">
                  <label>Progress Notes</label>
                  <textarea
                    class="form-control form-control-sm"
                    rows="4"
                    formControlName="note"
                  ></textarea>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Events</label>
                  <textarea
                    class="form-control form-control-sm"
                    rows="4"
                    formControlName="event"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Shift</label>
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    formControlName="shift"
                  >
                    <option value="Morning">Morning</option>
                    <option value="Evening">Evening</option>
                    <option value="Night">Night</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Handing over staff</label>
                <div class="form-group selectOptionBottom serviceReport">
                  <select
                    class="form-control form-control-sm nursingPicker"
                    data-live-search="true"
                    formControlName="handling_over_staff"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let data of staffNurses">
                      <option [value]="data.id">{{ data?.name }}</option>
                    </ng-container>
                    <!--                                <option *ngFor="let emp of allEmployees" [value]="emp.id">{{emp?.first_name + ' ' + emp?.last_name}} <span *ngIf="emp?.designation_name">({{emp?.designation_name}})</span></option>-->
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Receiving staff</label>
                <div class="form-group selectOptionBottom serviceReport">
                  <select
                    class="form-control form-control-sm nursingPicker"
                    data-live-search="true"
                    formControlName="receiving_staff"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let data of staffNurses">
                      <option [value]="data.id">{{ data?.name }}</option>
                    </ng-container>
                    <!--                                <option *ngFor="let emp of allEmployees" [value]="emp.id">{{emp?.first_name + ' ' + emp?.last_name}} <span *ngIf="emp?.designation_name">({{emp?.designation_name}})</span></option>-->
                  </select>
                </div>
              </div>
            </div>

            <div class="row m-0 my-6">
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 14%">
                        <span class="d-block">Weight</span
                        ><span class="small-label">Kg</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">Temp</span
                        ><span class="small-label">F</span>
                      </th>
                      <th style="width: 16%">
                        <span class="d-block">BP-Sys</span
                        ><span class="small-label">mm/hg</span>
                      </th>
                      <th style="width: 16%">
                        <span class="d-block">BP-Dias</span
                        ><span class="small-label">mm/hg</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block font-weight-bolder">Pulse</span
                        ><span class="small-label">/min</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">Resp.Rate</span
                        ><span class="small-label">/min</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">SPO2</span
                        ><span class="small-label">%</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr formGroupName="vital_attributes">
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="weight"
                          placeholder="1..250"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="temperature"
                          placeholder="95..107"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="bp_systolic"
                          placeholder="50..300"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="bp_diastolic"
                          placeholder="0..150"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="pulse"
                          placeholder="40..300"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="resp_rate"
                          placeholder="12..120"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="o2_saturation"
                          placeholder="0..100"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row text-right">
              <div class="col-12">
                <button class="btn btn-primary btn-sm" type="submit">
                  Add
                </button>
              </div>
            </div>

            <div class="row text-right">
              <div class="col-12">
                <!-- <button class="btn btn-success" (click)="addVitals('submit')">Submit</button> -->
              </div>
            </div>
          </form>

          <hr *ngIf="isAdd === true" />

          <div class="row" *ngIf="notesListNurse.length > 0">
            <div class="col-3">
              <label class="checkbox checkbox-sm mt-0 ml-3">
                <input
                  type="checkbox"
                  class="notesAll"
                  id="nurseNotesAll"
                  (change)="selectAllNotes($event.target.checked)"
                />
                <span class="mr-1"></span>Select All
              </label>
            </div>
          </div>

          <ng-container *ngFor="let data of notesListNurse; let i = index">
            <div class="row mb-3">
              <div class="col-6">
                <label class="checkbox checkbox-sm ml-3">
                  <input
                    type="checkbox"
                    class="notesCheckbox"
                    id="nurseingNotesCheck{{ i }}"
                    (change)="
                      selectNotes($event.target.checked, data.id, 'nursing')
                    "
                  />
                  <span></span>
                </label>
              </div>
              <div
                class="col-6 text-right"
                *ngIf="isAdd === true && editMoNursingNotesControl"
              >
                <a
                  class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                  (click)="patchNursingNotes(data)"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24"></rect>
                        <path
                          d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                        ></path>
                        <path
                          d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                          fill="#000000"
                          fill-rule="nonzero"
                          opacity="0.3"
                        ></path>
                      </g>
                    </svg>
                    <!--end::Svg Icon-->
                  </span>
                </a>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-12 align-self-start">
                <div class="row m-0 mb-1">
                  <div class="col-12">
                    <div class="d-block">
                      <span>{{
                        data?.created_at | date: "dd-MM-yyyy h:mm a"
                      }}</span>
                      <!--                                <span class="ml-2"><strong>Shift:</strong> {{data?.shift}}</span>-->
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-12 align-self-start">
                    <div class="d-block">
                      <span><strong>Written By:</strong></span
                      ><span> {{ data?.written_by?.full_name }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Ward:</strong></span
                      ><span> {{ data?.ward?.title }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Bed:</strong></span
                      ><span> {{ data?.bed?.bed_number }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Shift:</strong></span
                      ><span> {{ data?.shift }}</span>
                    </div>
                  </div>
                  <!--                            <div class="col-8 align-self-start p-0">-->
                  <!--                              <div class="d-block"></div>-->
                  <!--                              <div class="d-block" *ngIf="patient?.admissions"></div>-->
                  <!--                              <div class="d-block" *ngIf="patient?.bed_name"></div>-->
                  <!--                              &lt;!&ndash;                              <div class="d-block"><span>{{data?.shift}}</span></div>&ndash;&gt;-->
                  <!--                            </div>-->
                </div>
              </div>

              <div
                class="col-lg-8 col-md-8 col-sm-12 col-12 mt-2 mt-sm-2 mt-md-0"
                style="border-left: 1px solid rgba(0, 0, 0, 0.1)"
              >
                <div class="row mx-0 mb-2">
                  <div class="col-12">
                    <span class="d-block"
                      ><span><strong>Notes</strong></span></span
                    >
                  </div>
                  <div class="col-12">
                    <span [innerHTML]="data?.update_nursing_note"></span>
                  </div>
                </div>

                <div class="row mx-0 mb-2">
                  <div class="col-12">
                    <span class="d-block"><strong>Event</strong></span>
                  </div>
                  <div class="col-12">
                    <span [innerHTML]="data?.update_nursing_event"></span>
                  </div>
                </div>

                <div class="row mx-0">
                  <div class="col-12">
                    <div class="w-100">
                      <span class="d-block"><strong>Vitals</strong></span>
                      <div class="table-responsive text-nowrap">
                        <table class="table table-vertical-center">
                          <thead>
                            <tr>
                              <th class="pt-1 pb-0 px-1" style="width: 14%">
                                <span class="d-block text-muted">Weight</span
                                ><span class="small-label">kg</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 14%">
                                <span class="d-block text-muted">Temp.</span
                                ><span class="small-label">F</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 16%">
                                <span class="d-block text-muted">BP-Sys</span
                                ><span class="small-label">mm/hg</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 16%">
                                <span class="d-block text-muted">BP-Dias</span
                                ><span class="small-label">mm/hg</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 14%">
                                <span class="d-block text-muted">Pulse</span
                                ><span class="small-label">/min</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 14%">
                                <span class="d-block text-muted">Resp.Rate</span
                                ><span class="small-label">/min</span>
                              </th>
                              <th class="pt-1 pb-0 px-1" style="width: 14%">
                                <span class="d-block text-muted">SPO2</span
                                ><span class="small-label">%</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="data?.vital">
                              <td>{{ data?.vital?.weight }}</td>
                              <td>{{ data?.vital?.temperature }}</td>
                              <td>{{ data?.vital?.bp_systolic }}</td>
                              <td>{{ data?.vital?.bp_diastolic }}</td>
                              <td>{{ data?.vital?.pulse }}</td>
                              <td>{{ data?.vital?.resp_rate }}</td>
                              <td>{{ data?.vital?.o2_saturation }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
          </ng-container>

          <div class="row mt-4 text-right" *ngIf="notesListNurse.length > 0">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-light-primary btn-icon btn-sm"
                (click)="printPatientNotes('nursing')"
              >
                <i class="fas fa-print"></i>
              </button>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="dutydoctor"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_4"
        >
          <div class="row m-0" *ngIf="isAdd === true">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label>Progress Notes <span class="text-danger">*</span></label>
                <textarea
                  class="form-control form-control-sm"
                  rows="4"
                  [(ngModel)]="progressNotes"
                ></textarea>
              </div>
            </div>
            <div class="col-md-3 col-sm-3">
              <div class="form-group">
                <label>Shift <span class="text-danger">*</span></label>
                <select
                  class="form-control form-control-sm"
                  [(ngModel)]="progressNotesShift"
                >
                  <option value="Morning">Morning</option>
                  <option value="Evening">Evening</option>
                  <option value="Night">Night</option>
                </select>
              </div>
            </div>
            <!--            <div class="col-md-4 col-sm-4 selectOptionBottom">-->
            <!--              <div class="form-group ">-->
            <!--                <label>Doctor <span class="text-danger">*</span></label>-->
            <!--                <select class="form-control form-control-sm doctorNotes pickers" data-container="body"-->
            <!--                        data-live-search="true" [(ngModel)]="progressNotesDoctor">-->
            <!--                  <option [value]="d.id" *ngFor="let d of doctors">{{d.first_name}} {{d.last_name}}</option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>

          <div class="row mx-0 mb-4 text-right" *ngIf="isAdd === true">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                (click)="showDoctorUpdateConfirmationModal('', true)"
              >
                Add
              </button>
            </div>
          </div>

          <hr *ngIf="isAdd === true" />

          <div
            class="max-height-250 overflow-y-auto overflow-auto table-responsive mt-8"
          >
            <table class="table table-head-custom table-vertical-center">
              <thead>
                <tr>
                  <th style="width: 5%">
                    <label
                      class="checkbox checkbox-sm justify-content-center mt-0"
                    >
                      <input
                        type="checkbox"
                        class="notesAll"
                        id="doctorNotesAll"
                        (change)="selectAllNotes($event.target.checked)"
                      />
                      <span></span>
                    </label>
                  </th>
                  <th style="width: 20%">Date & Time</th>
                  <th style="width: 20%">Doctor</th>
                  <th style="width: 45%">Notes</th>
                  <th
                    *ngIf="editMoNursingNotesControl === true"
                    style="width: 10%"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let notes of notesListDoc; let i = index">
                  <td style="width: 5%">
                    <label
                      class="checkbox checkbox-sm justify-content-center mt-0"
                    >
                      <input
                        type="checkbox"
                        class="notesCheckbox"
                        id="doctorNotesCheck{{ i }}"
                        (change)="
                          selectNotes($event.target.checked, notes.id, 'doctor')
                        "
                      />
                      <span></span>
                    </label>
                  </td>
                  <td style="width: 20%">
                    {{ notes?.created_at | date: "dd-MM-yyyy h:mm a" }}
                  </td>
                  <td style="width: 20%">{{ notes?.doctor?.full_name }}</td>
                  <td style="width: 45%">
                    <span [innerHTML]="notes?.update_doctor_note"></span>
                  </td>
                  <td
                    *ngIf="isAdd === true && editMoNursingNotesControl === true"
                    style="width: 10%"
                  >
                    <a
                      class="btn btn-icon btn-sm btn-light btn-hover-primary btn-sm mx-3"
                      (click)="patchDoctorNotes(notes)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon | path:assets/media/svg/icons/Communication/Write.svg-->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24"></rect>
                            <path
                              d="M12.2674799,18.2323597 L12.0084872,5.45852451 C12.0004303,5.06114792 12.1504154,4.6768183 12.4255037,4.38993949 L15.0030167,1.70195304 L17.5910752,4.40093695 C17.8599071,4.6812911 18.0095067,5.05499603 18.0083938,5.44341307 L17.9718262,18.2062508 C17.9694575,19.0329966 17.2985816,19.701953 16.4718324,19.701953 L13.7671717,19.701953 C12.9505952,19.701953 12.2840328,19.0487684 12.2674799,18.2323597 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              transform="translate(14.701953, 10.701953) rotate(-135.000000) translate(-14.701953, -10.701953)"
                            ></path>
                            <path
                              d="M12.9,2 C13.4522847,2 13.9,2.44771525 13.9,3 C13.9,3.55228475 13.4522847,4 12.9,4 L6,4 C4.8954305,4 4,4.8954305 4,6 L4,18 C4,19.1045695 4.8954305,20 6,20 L18,20 C19.1045695,20 20,19.1045695 20,18 L20,13 C20,12.4477153 20.4477153,12 21,12 C21.5522847,12 22,12.4477153 22,13 L22,18 C22,20.209139 20.209139,22 18,22 L6,22 C3.790861,22 2,20.209139 2,18 L2,6 C2,3.790861 3.790861,2 6,2 L12.9,2 Z"
                              fill="#000000"
                              fill-rule="nonzero"
                              opacity="0.3"
                            ></path>
                          </g>
                        </svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="row text-right m-0 mt-4" *ngIf="notesListDoc.length > 0">
            <div class="col-12">
              <button
                class="btn btn-primary btn-sm"
                (click)="printPatientNotes('doctor')"
              >
                Print
              </button>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="pharmacistNotes"
          role="tabpanel"
          aria-labelledby="kt_tab_pane_2_4"
        >
          <form
            *ngIf="isAdd === true"
            [formGroup]="pharmacistForm"
            (ngSubmit)="submitPharmacist()"
            class="d-print-none"
          >
            <div class="row m-0">
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <!--                            <label> <span class="text-danger">*</span></label>-->
                  <div class="d-flex">
                    <label>Appropriate Dose </label>
                    <span class="switch switch-icon ml-5">
                      <label>
                        <input
                          type="checkbox"
                          formControlName="appropriate_dose"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <!--                            <label>Appropiate Route <span class="text-danger">*</span></label>-->
                  <div class="d-flex">
                    <label>Appropriate Route </label>
                    <span class="switch switch-icon ml-5">
                      <label>
                        <input
                          type="checkbox"
                          formControlName="appropriate_route"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <!--                            <label>Appropiate Route <span class="text-danger">*</span></label>-->
                  <div class="d-flex">
                    <label>Appropriate Time </label>
                    <span class="switch switch-icon ml-5">
                      <label>
                        <input
                          type="checkbox"
                          formControlName="appropriate_time"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <!--                            <label>Appropiate Route <span class="text-danger">*</span></label>-->
                  <div class="d-flex">
                    <label>FDA Approved Drug</label>
                    <span class="switch switch-icon ml-5">
                      <label>
                        <input
                          type="checkbox"
                          formControlName="fda_approved_drug"
                        />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <!--                            <label>Appropiate Route <span class="text-danger">*</span></label>-->
                  <div class="d-flex">
                    <label>Any Reaction</label>
                    <span class="switch switch-icon ml-5">
                      <label>
                        <input type="checkbox" formControlName="any_reaction" />
                        <span></span>
                      </label>
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                  <label>Notes <span class="text-danger">*</span></label>
                  <textarea
                    class="form-control form-control-sm"
                    rows="4"
                    formControlName="note"
                    [ngClass]="{
                      'is-invalid':
                        isTrue && this.pharmacistForm.controls.note.errors
                    }"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mx-0 mb-4">
              <div class="col-6 text-left">
                <span *ngIf="this.notesListPharmacist.length > 0">
                  <a
                    class="btn btn-primary btn-sm"
                    (click)="printCurrentScreen()"
                    >Print</a
                  >
                </span>
              </div>
              <div class="col-6 text-right" *ngIf="isAdd === true">
                <button type="submit" class="btn btn-primary btn-sm">
                  Add
                </button>
              </div>
            </div>
          </form>

          <hr *ngIf="isAdd === true" />
          <div class="row">
            <div class="col-12 text-left" *ngIf="isAdd === false">
              <a class="btn btn-primary btn-sm" (click)="printCurrentScreen()"
                >Print</a
              >
              <hr />
            </div>
            <div class="col-12 mb-6 d-none d-print-block">
              <div style="width: 85%; float: left">
                <table class="w-100 info_table mb-2">
                  <tbody>
                    <tr>
                      <td style="width: 45%">
                        <h3
                          *ngIf="patient"
                          class="m-0 line-clamp font-print-bold mr-5"
                        >
                          {{ patient?.title | titlecase }}
                          {{
                            patient?.first_name + " " + patient?.last_name + ","
                              | titlecase
                          }}
                          {{ patient?.age + "," }}
                          {{ patient?.gender.charAt(0) | titlecase }}
                        </h3>
                      </td>
                      <td style="width: 55%">
                        <h3 class="m-0 font-print-bold">
                          MRN#{{ patient?.mrn }}
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <!-- patient info detail table -->
                <table class="w-100 info_table">
                  <tbody>
                    <tr>
                      <td style="width: 15%">
                        <strong>Father/Husband:</strong>
                      </td>
                      <td style="width: 20%">
                        {{ patient?.father_name | titlecase }}
                      </td>
                      <td style="width: 10%"></td>
                      <td style="width: 15%"><strong>Patient Type:</strong></td>
                      <td style="width: 20%">
                        {{ patient?.patient_type?.name }}
                      </td>
                      <td style="width: 20%"></td>
                    </tr>
                    <tr>
                      <td style="width: 15%"><strong>Contact:</strong></td>
                      <td style="width: 20%">{{ patient?.phone1 }}</td>
                      <td style="width: 10%"></td>
                      <td style="width: 15%"><strong>Reg. Date:</strong></td>
                      <td style="width: 20%">
                        {{ patient?.created_at | date: "dd-MM-yyyy h:mm a" }}
                      </td>
                      <td style="width: 20%"></td>
                    </tr>
                    <tr>
                      <td style="width: 15%"><strong>CNIC:</strong></td>
                      <td style="width: 20%">{{ patient?.patient_nic }}</td>
                      <td style="width: 10%"></td>
                      <td style="width: 15%"><strong>Consultant:</strong></td>
                      <td style="width: 20%">{{ patient?.consultant_name }}</td>
                      <td style="width: 20%"></td>
                    </tr>
                    <tr>
                      <td style="width: 15%"><strong>Address:</strong></td>
                      <td style="width: 20%">{{ patient?.address1 }}</td>
                      <td style="width: 10%"></td>
                      <td style="width: 15%"><strong>Department:</strong></td>
                      <td style="width: 20%">
                        {{ patient?.admissions[0].referred_to }}
                      </td>
                      <td style="width: 20%"></td>
                    </tr>
                    <tr>
                      <td style="width: 15%"><strong>Admission:</strong></td>
                      <td style="width: 20%">
                        {{
                          patient?.admissions[0].created_at
                            | date: "dd-MM-yyyy h:mm a"
                        }}
                      </td>
                      <td style="width: 10%"></td>
                      <td style="width: 15%"><strong>Admission No:</strong></td>
                      <td style="width: 20%">
                        {{ patient?.admissions[0].id }}
                      </td>
                      <td style="width: 20%"></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div style="width: 15%; float: left">
                <div style="width: 100%; height: 180px">
                  <img
                    class="qrCode"
                    style="height: 100%; width: 100%"
                    *ngIf="patient?.qrcode_url"
                    [src]="patient?.qrcode_url"
                    alt=""
                  />
                </div>
                <img
                  style=""
                  class="brCode"
                  *ngIf="patient?.barcode_url"
                  [src]="patient?.barcode_url"
                  alt=""
                />
                <div
                  style=""
                  class="font-weight-bolder font-size-h3 mrnCode"
                  id="mrn"
                >
                  {{ patient?.mrn }}
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngFor="let data of notesListPharmacist; let i = index">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-12 col-12 align-self-start">
                <div class="row m-0 mb-1">
                  <div class="col-12">
                    <div class="d-block">
                      <span
                        ><strong>{{
                          data?.updated_at | date: "dd-MM-yyyy h:mm a"
                        }}</strong></span
                      >
                    </div>
                  </div>
                </div>

                <div class="row m-0">
                  <div class="col-12 align-self-start">
                    <div class="d-block">
                      <span><strong>Appropriate Dose:</strong></span>
                      <span> {{ data?.appropriate_dose ? "Yes" : "No" }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Appropriate Route:</strong></span>
                      <span> {{ data?.appropriate_route ? "Yes" : "No" }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Appropriate Time:</strong></span>
                      <span> {{ data?.appropriate_time ? "Yes" : "No" }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>FDA Approved Drug:</strong></span>
                      <span> {{ data?.fda_approved_drug ? "Yes" : "No" }}</span>
                    </div>
                    <div class="d-block">
                      <span><strong>Any Reaction:</strong></span>
                      <span> {{ data?.any_reaction ? "Yes" : "No" }}</span>
                    </div>
                  </div>
                  <!--                            <div class="col-8 align-self-start p-0">-->
                  <!--                              <div class="d-block"></div>-->
                  <!--                              <div class="d-block" *ngIf="patient?.admissions"></div>-->
                  <!--                              <div class="d-block" *ngIf="patient?.bed_name"></div>-->
                  <!--                              &lt;!&ndash;                              <div class="d-block"><span>{{data?.shift}}</span></div>&ndash;&gt;-->
                  <!--                            </div>-->
                </div>
              </div>

              <div
                class="col-lg-8 col-md-8 col-sm-12 col-12 mt-2 mt-sm-2 mt-md-0 align-self-center"
                style="border-left: 1px solid rgba(0, 0, 0, 0.1)"
              >
                <div class="row mx-0 mb-2">
                  <div class="col-12">
                    <span class="d-block"
                      ><span><strong>Written By:</strong></span></span
                    >
                  </div>
                  <div class="col-12">
                    <span [innerHTML]="data?.user?.full_name"></span>
                  </div>
                </div>

                <div class="row mx-0 mb-2">
                  <div class="col-12">
                    <span class="d-block"><strong>Notes</strong></span>
                  </div>
                  <div class="col-12">
                    <span [innerHTML]="data?.note"></span>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!--end::Card-->
</div>

<!--Nursung Notes Modal-->
<div class="example-preview">
  <div>
    <ng-template #updateNursingModal let-c="close" let-d="dismiss">
      <div class="">
        <form [formGroup]="updateProgressNotesForm">
          <div class="modal-header p-3">
            <h4 class="modal-title">Update Nursing Notes</h4>
            <a
              type="button"
              class="close"
              aria-label="Close"
              (click)="d('Cross click'); updateNursingNotesId = ''"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div class="row m-0">
              <div class="col-12">
                <div class="form-group">
                  <label>Progress Notes</label>
                  <textarea
                    class="form-control form-control-sm"
                    rows="4"
                    formControlName="note"
                  ></textarea>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>Events</label>
                  <textarea
                    class="form-control form-control-sm"
                    rows="4"
                    formControlName="event"
                  ></textarea>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Shift</label>
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    formControlName="shift"
                  >
                    <option value="Morning">Morning</option>
                    <option value="Evening">Evening</option>
                    <option value="Night">Night</option>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Handing over staff</label>
                <div class="form-group selectOptionBottom serviceReport">
                  <select
                    class="form-control form-control-sm nursingPicker"
                    id="updateNursePicker"
                    data-live-search="true"
                    formControlName="handling_over_staff"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let data of staffNurses">
                      <option [value]="data.id">{{ data?.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-4 col-sm-6 col-12">
                <label>Receiving staff</label>
                <div class="form-group selectOptionBottom serviceReport">
                  <select
                    class="form-control form-control-sm nursingPicker"
                    id="updateReceiveingStaffPicker"
                    data-live-search="true"
                    formControlName="receiving_staff"
                  >
                    <option value="">Select</option>
                    <ng-container *ngFor="let data of staffNurses">
                      <option [value]="data.id">{{ data?.name }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>

            <div class="row m-0 my-6">
              <div class="table-responsive text-nowrap">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 14%">
                        <span class="d-block">Weight</span
                        ><span class="small-label">Kg</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">Temp</span
                        ><span class="small-label">F</span>
                      </th>
                      <th style="width: 16%">
                        <span class="d-block">BP-Sys</span
                        ><span class="small-label">mm/hg</span>
                      </th>
                      <th style="width: 16%">
                        <span class="d-block">BP-Dias</span
                        ><span class="small-label">mm/hg</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block font-weight-bolder">Pulse</span
                        ><span class="small-label">/min</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">Resp.Rate</span
                        ><span class="small-label">/min</span>
                      </th>
                      <th style="width: 14%">
                        <span class="d-block">SPO2</span
                        ><span class="small-label">%</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr formGroupName="vital_attributes">
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="weight"
                          placeholder="1..250"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="temperature"
                          placeholder="95..107"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="bp_systolic"
                          placeholder="50..210"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="bp_diastolic"
                          placeholder="33..120"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="pulse"
                          placeholder="40..300"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="resp_rate"
                          placeholder="12..120"
                        />
                      </td>
                      <td>
                        <input
                          class="form-control form-control-sm text-center"
                          formControlName="o2_saturation"
                          placeholder="0..100"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer p-3">
            <a
              type="button"
              class="btn btn-sm btn-primary"
              (click)="c('Close click'); updateNursingNotesId = ''"
              >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
              (submit)="showNursingUpdateConfirmationModal()"
              (click)="showNursingUpdateConfirmationModal()"
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>

<!--Doctor Notes Modal-->
<div class="example-preview">
  <div>
    <ng-template
      #updateDoctorNotesModal
      let-c="close"
      data-backdrop="false"
      let-d="dismiss"
    >
      <div class="">
        <!--        <form>-->
        <div class="modal-header p-3">
          <h4 class="modal-title">Update Doctor Notes</h4>
          <a
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click'); updateDoctorNotesId = ''"
          >
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div class="row m-0">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label>Progress Notes</label>
                <textarea
                  class="form-control form-control-sm"
                  rows="4"
                  [(ngModel)]="updatedProgressNotes"
                ></textarea>
              </div>
            </div>
            <div class="col-md-3 col-sm-3">
              <div class="form-group">
                <label>Shift</label>
                <select
                  class="form-control form-control-sm"
                  [(ngModel)]="updatedProgressNotesShift"
                >
                  <option value="Morning">Morning</option>
                  <option value="Evening">Evening</option>
                  <option value="Night">Night</option>
                </select>
              </div>
            </div>
            <!--            <div class="col-md-4 col-sm-4 selectOptionBottom">-->
            <!--              <div class="form-group ">-->
            <!--                <label>Doctor</label>-->
            <!--                <select class="form-control form-control-sm doctorNotes pickers" data-container="body"-->
            <!--                        data-live-search="true" [(ngModel)]="updatedProgressNotesDoctor">-->
            <!--                  <option [value]="d.id" *ngFor="let d of doctors">{{d.first_name}} {{d.last_name}}</option>-->
            <!--                </select>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="modal-footer p-3">
          <a
            type="button"
            class="btn btn-sm btn-primary"
            (click)="c('Close click'); updateDoctorNotesId = ''"
            >Close</a
          >
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (submit)="showDoctorUpdateConfirmationModal('', false)"
            (click)="showDoctorUpdateConfirmationModal('', false)"
          >
            Update
          </button>
        </div>
        <!--        </form>-->
      </div>
    </ng-template>
  </div>
</div>
<!--User id and Password Modal-->
<div
  *ngIf="openUserConfirmationModal"
  id="nursingNotesModal"
  class="modal fade"
  data-backdrop="false"
  style="z-index: 1051 !important"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="!updateNursingNotesId">
          Nursing Notes Confirmation
        </h4>
        <h4 class="modal-title" *ngIf="updateNursingNotesId">
          Update Nursing Notes Confirmation
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeNursingModal()"
        >
          <i aria-hidden="true" class="ki ki-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label
                  >Emp ID / User Name:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employe ID"
                  name="empId"
                  [(ngModel)]="empId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name=""
                  [(ngModel)]="userPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfimedNursing"
                />
                <span></span>
              </label>
              <span *ngIf="!updateNursingNotesId"
                >Are you sure you want to add Nursing Notes?</span
              >
              <span *ngIf="updateNursingNotesId"
                >Are you sure you want to update Nursing Notes?</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          (click)="closeNursingModal(); updateNursingNotesId = ''"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="!updateNursingNotesId"
          (click)="confirmNursingNotes()"
        >
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="updateNursingNotesId"
          (click)="updateNursingNotes()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="openDutyDoctorUserConfirmationModal"
  id="doctorNotesModal"
  class="modal fade"
  data-backdrop="false"
  style="z-index: 1051 !important"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="!updateDoctorNotesId">
          Doctor Notes Confirmation
        </h4>
        <h4 class="modal-title" *ngIf="updateDoctorNotesId">
          Update Doctor Notes Confirmation
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i
            aria-hidden="true"
            class="ki ki-close"
            (click)="updateDoctorNotesId = ''; closeDutyDoctorModal()"
          ></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex">
              <div class="form-group">
                <label
                  >Emp ID / User Name:<span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Employe ID"
                  name="empId"
                  [(ngModel)]="doctorEmpId"
                />
              </div>
              <div class="form-group pl-8">
                <label>User Password:<span class="text-danger">*</span></label>
                <input
                  type="password"
                  class="form-control form-control-sm"
                  placeholder="Password"
                  name=""
                  [(ngModel)]="doctorUserPassword"
                />
              </div>
            </div>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input
                  type="checkbox"
                  name="Checkbox1"
                  [(ngModel)]="isConfirmedDoctor"
                />
                <span></span>
              </label>
              <span *ngIf="!updateDoctorNotesId">
                Are you sure you want to add Doctor Notes?</span
              >
              <span *ngIf="updateDoctorNotesId"
                >Are you sure you want to Update Doctor Notes?</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-3">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          (click)="updateDoctorNotesId = ''; closeDutyDoctorModal()"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="!updateDoctorNotesId"
          (click)="addDoctorProgressNotes('')"
        >
          Confirm
        </button>
        <button
          type="button"
          class="btn btn-primary"
          *ngIf="updateDoctorNotesId"
          (click)="updateDoctorNotes()"
        >
          Confirm
        </button>
        <!--        <button type="submit" [disabled]="isButtonClicked" class="btn btn-primary" (click)="deleteGeneral()">Confirm</button>-->
      </div>
    </div>
  </div>
</div>
