import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { DepartmentsByHospitalService } from '../../../services/departments-by-hospital.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RosterServiceService } from '../../../services/roster-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteShareService } from '../../../services/route-share.service';
import { UserService } from '../../../services/user.service';
import * as moment from 'moment/moment';
import { TaskManagementService } from '../../../services/task-management.service';
declare var $: any;
declare var toastr: any;
@Component({
  selector: 'app-user-status-report',
  templateUrl: './user-status-report.component.html',
  styleUrls: ['./user-status-report.component.css'],
})
export class UserStatusReportComponent implements OnInit {
  today = formatDate(new Date(), 'MM-yyyy', 'en');
  fromDate = this.today;
  toDate = this.today;
  from_date: any;
  to_date: any;
  // lastMonth: any;
  depart: any;
  employeesOfDepart: any;
  employeesOfSelectedDepartment: any = [];
  selectedLocation = '';
  dates: any;
  employeeRoasterData = [];
  roasterData = [];
  shiftData = [];
  locationData = [];
  department: any;
  month: any;
  selectedMonthRoster: any;
  shiftFirstData: any;
  applyToAllConfirmation = false;
  applyToAllIndex: any;
  singleShiftIndex: any;
  removeShiftIndex: any;
  singleShiftEmployeeIndex: any;
  removeSingleEmployeeShift = false;
  selectedLocationAdd = '';
  selectedShiftAdd = '';
  removeShiftId: any;
  newCustomizeArray: any = [];
  verticalCount: number = 0;
  singleShiftEmployee: any;
  previousAddedShiftsDates: any;
  fromEmployee: any;
  toEmployee: any;
  showSelectAll = true;
  showDeselectAll = false;
  fromMonth: any;
  toMonth: any;
  copyRosterCheck = false;
  copyRosterEmployee: any;
  rosterDaysOff: any;
  rosterDaysOffCheck = false;
  closeRoster = false;
  betweenDates: any;
  selectedEmployeeForDayOff: any;
  selectedEmployeeForRosterCopy: any = new Array();
  copyRosterCheckForReplaceOrAppend: string;
  screenName = '';
  screenControls = [];
  isrosterclose = false;
  taskStatus: any;
  task_status: any;
  priority: any;
  task_priority: any;
  employee: any;
  employeeSelect: any;
  project: any;
  task_project: any;

  constructor(
    private departmentsByHospital: DepartmentsByHospitalService,
    private configurationService: ConfigurationService,
    private ngxLoader: NgxUiLoaderService,
    private rosterServiceService: RosterServiceService,
    private route: ActivatedRoute,
    private router: Router,
    private routeSevice: RouteShareService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private taksServices: TaskManagementService,
  ) {
    this.screenName = this.routeSevice.getValue();
    this.getShiftListing();
    this.getDepartments();
  }

  getScreenControls(): any {
    this.ngxLoader.start();
    this.userService.UserControlByScreenV2().subscribe(
      (data) => {
        this.screenControls = data.roles;
        if (
          this.screenControls.filter((m) => m.code === 'isrosterclose').length >
          0
        ) {
          this.isrosterclose =
            this.screenControls.filter((m) => m.code === 'isrosterclose')[0]
              .is_show || false;
        }
        // this.updateControlData();
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
      },
    );
  }

  getShiftListing(): any {
    this.ngxLoader.start();
    this.configurationService.fetchShiftListing('', '', '', true).subscribe(
      (data) => {
        this.shiftData = data;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  }

  getDepartments(): any {
    this.rosterServiceService.getDepartmentLocation().subscribe(
      (data) => {
        this.depart = data.department;
        this.locationData = data.location;
        setTimeout((e) => {
          $('.selectpicker').selectpicker('refresh');
          $('.selectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  getEmployeesOfDepartment(): any {
    this.employeesOfDepart = [];
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
      $('#employeeDepartmentList').selectpicker();
    }, 500);
    if (!this.department.length) {
      return;
    }
    setTimeout((e) => {
      $('#employeeDepartmentList').selectpicker('refresh');
    }, 200);
    this.configurationService
      .getEmployeesOfSpecificDepartment(this.department)
      .subscribe(
        (data) => {
          this.employeesOfDepart = data;
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
            $('#employeeDepartmentList').selectpicker();
          }, 500);
        },
        (err) => {
          setTimeout((e) => {
            $('#employeeDepartmentList').selectpicker('refresh');
          }, 500);
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }
  preventChangeDate(): any {
    return false;
  }
  appendArray(): any {
    this.from_date = $('#fromDate').val();
    this.to_date = $('#toDate').val();
    console.log('start date', this.from_date);
    console.log('end date', this.to_date);
    console.log('status', this.task_status);
    console.log('priority', this.task_priority);
    console.log('Employee', this.employeeSelect);
    // if (!this.department) {
    //   toastr.error('Select Department');
    //   return;
    // }
    // this.selectedEmployeeForRosterCopy = [];
    // console.log('selectedEmployeeForRosterCopy', this.selectedEmployeeForRosterCopy);
    // if (this.employeesOfSelectedDepartment.length) {
    //   for (let i = 0; i < this.employeesOfDepart.length; i++) {
    //     for (let j = 0; j < this.employeesOfSelectedDepartment.length; j++) {
    //       if (this.employeesOfDepart[i].id === this.employeesOfSelectedDepartment[j]) {
    //         this.selectedEmployeeForRosterCopy.push(this.employeesOfDepart[i]);
    //       }
    //     }
    //   }
    // } else {
    //   for (let i = 0; i < this.employeesOfDepart.length; i++) {
    //     this.selectedEmployeeForRosterCopy.push(this.employeesOfDepart[i]);
    //   }
    // }
    // this.lastMonth = moment('01-' + this.month, 'dd-MM-yyyy').subtract(1, 'months').format('DD-MM-YYYY');
    // this.month = $('.month').val();
    // if (this.month === '') {
    //   toastr.error('Select Month');
    //   return;
    // }
    // if (this.employeesOfSelectedDepartment === undefined) {
    //   this.employeesOfSelectedDepartment = '';
    // }

    this.ngxLoader.start();
    this.taksServices
      .getEmployTask(
        this.from_date,
        this.to_date,
        this.task_priority,
        this.task_status,
        this.employeeSelect,
        this.task_project,
      )
      .subscribe(
        (data) => {
          this.employeeRoasterData = data;
          console.log('employee profile', this.employeeRoasterData);
          // console.log('this.employeeRoasterData', this.employeeRoasterData);
          // this.selectedMonthRoster = data.month;
          setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
          }, 100);
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  }

  confirmClose(): any {
    $('#closeConfirmModal').modal('show');
  }

  onConfirmRosterClose(): any {
    this.onSubmit(true);
    $('#closeConfirmModal').modal('hide');
  }

  onSubmit(data): any {
    this.ngxLoader.start();
    this.closeRoster = data;
    console.log('roster close=', this.closeRoster);
    this.rosterServiceService
      .submitEmployRoaster(
        this.employeeRoasterData,
        this.selectedMonthRoster,
        this.closeRoster,
      )
      .subscribe(
        (data) => {
          this.ngxLoader.stop();
          if (this.closeRoster === true) {
            toastr.success('Closed Successfully');
          } else {
            toastr.success('Created Successfully');
          }
          this.appendArray();
        },
        (err) => {
          if (err.error.data) {
            this.employeeRoasterData = err.error.data;
            toastr.error('Shift timing overlap');
          } else {
            toastr.error(err.error.message);
          }
          this.ngxLoader.stop();
        },
      );
  }

  getDaysOfWeekHorizental(column, value): any {
    const data = $(
      '#rosterCalendar table tbody tr td:nth-child(' +
        column +
        ').day:not(.old):not(.new)',
    );
    if (value) {
      for (const x of data) {
        this.newCustomizeArray.push(
          x.innerText > 9
            ? x.innerText + '-' + $('.month').val()
            : '0' + x.innerText + '-' + $('.month').val(),
        );
      }
    } else {
      for (const x of data) {
        const findIndex = this.newCustomizeArray.indexOf(
          x.innerText > 9
            ? x.innerText + '-' + $('.month').val()
            : '0' + x.innerText + '-' + $('.month').val(),
        );
        if (findIndex !== -1) {
          this.newCustomizeArray.splice(findIndex, 1);
        }
      }
    }
    $('#rosterCalendar').datepicker('update');
    setTimeout(() => {
      $('#rosterCalendar').datepicker('setDates', this.newCustomizeArray);
    }, 50);
  }

  getDaysOfWeek(week, value): any {
    const data = $(
      '#rosterCalendar table tbody tr:nth-child(' +
        week +
        ') td.day:not(.old):not(.new)',
    );
    if (value) {
      for (const x of data) {
        this.newCustomizeArray.push(
          x.innerText > 9
            ? x.innerText + '-' + $('.month').val()
            : '0' + x.innerText + '-' + $('.month').val(),
        );
      }
    } else {
      for (const x of data) {
        const findIndex = this.newCustomizeArray.indexOf(
          x.innerText > 9
            ? x.innerText + '-' + $('.month').val()
            : '0' + x.innerText + '-' + $('.month').val(),
        );
        if (findIndex !== -1) {
          this.newCustomizeArray.splice(findIndex, 1);
        }
      }
    }
    $('#rosterCalendar').datepicker('update');
    setTimeout(() => {
      $('#rosterCalendar').datepicker('setDates', this.newCustomizeArray);
    }, 50);
  }

  openApplyToAllModal(ind, selectedEmployee, isClosed, employeeName): any {
    this.singleShiftEmployee = selectedEmployee;
    console.log('singleShiftEmployee', this.singleShiftEmployee);
    this.selectedShiftAdd = '';
    this.selectedLocationAdd = '';
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
    }, 500);
    if (isClosed) {
      toastr.error(
        `Roster of ${employeeName} for the month of ${this.month} has been closed`,
      );
      return;
    } else {
      $('.roasterSelection.popup').selectpicker('refresh');
      $('#rosterApplyToAll').modal('show');
    }
    // $('#rosterApplyToAll').modal('show');
    this.newCustomizeArray = [];
    setTimeout(() => {
      $('#rosterCalendar')
        .datepicker({
          inline: true,
          multidate: true,
          format: 'dd-mm-yyyy',
          orientation: 'bottom left',
          startDate: '01-' + this.month,
          showWeekDays: true,
        })
        .on('changeDate', (e) => {
          for (let x of e.dates) {
            this.newCustomizeArray.push(
              (new Date(x).getDate() > 9
                ? new Date(x).getDate()
                : '0' + new Date(x).getDate()) +
                '-' +
                this.month,
            );
          }
          const customizedArray = e.dates.map(
            (data) =>
              (new Date(data).getDate() > 9
                ? new Date(data).getDate()
                : '0' + new Date(data).getDate()) +
              '-' +
              this.month,
          );
          this.newCustomizeArray = this.newCustomizeArray.filter((val) =>
            customizedArray.includes(val),
          );
          this.newCustomizeArray = [...new Set(this.newCustomizeArray)];
        });
      this.verticalCount = $(
        '#rosterCalendar table tbody tr',
      ).children().length;
    }, 1000);
    this.applyToAllIndex = ind;
  }

  saveModalChanges(): any {
    if (this.selectedShiftAdd === '') {
      toastr.error('Select Shift');
      return;
    }
    if (this.selectedLocationAdd === '') {
      toastr.error('Select Location');
      return;
    }
    if (!this.newCustomizeArray.length) {
      toastr.error('Select Days');
      return;
    }
    if (this.applyToAllConfirmation === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    const shift = this.shiftData.find(
      (m) => m.id === Number(this.selectedShiftAdd),
    );
    const location = this.locationData.find(
      (m) => m.id === Number(this.selectedLocationAdd),
    );
    const obj = {
      shift_name: shift.name,
      shift_id: shift.id,
      shift_text_color: shift.shift_text_color,
      shift_bg_color: shift.shift_bg_color,
      location_name: location.name,
      location_id: location.id,
      is_removed: false,
      can_delete: true,
      status: 0,
    };
    for (
      let i = 0;
      i < this.employeeRoasterData[this.applyToAllIndex].roster_detail.length;
      i++
    ) {
      for (let j = 0; j < this.newCustomizeArray.length; j++) {
        if (
          this.employeeRoasterData[this.applyToAllIndex].roster_detail[i]
            .new_date === this.newCustomizeArray[j]
        ) {
          if (
            this.exists(
              this.employeeRoasterData[this.applyToAllIndex].roster_detail[i]
                ?.shift,
              obj.shift_id,
            )
          ) {
            this.employeeRoasterData[this.applyToAllIndex].roster_detail[
              i
            ].shift.push(obj);
          }
        }
      }
    }
    this.ngxLoader.stop();
    $('#rosterApplyToAll').modal('hide');
    this.applyToAllConfirmation = false;
  }

  exists(array, shift_id): any {
    let value = true;
    for (let i = 0; i < array.length; i++) {
      if (array[i].shift_id === shift_id) {
        return (value = false);
      }
    }
    return value;
  }

  removefromArray(index): any {
    this.employeeRoasterData.splice(index, 1);
  }

  selectDayOff(index, isClosed, employeeName): any {
    if (isClosed) {
      toastr.error(
        `Roster of ${employeeName} for the month of ${this.month} has been closed`,
      );
      return;
    } else {
      $('#dayOff').modal('show');
    }
    this.selectedEmployeeForDayOff = this.employeeRoasterData[index];
    this.rosterDaysOffCheck = false;
    this.rosterDaysOff = [];
    this.betweenDates = this.employeeRoasterData[index].roster_detail.map(
      (data) => {
        let obj = {
          new_date: data.new_date,
          is_day_off: data.is_day_off,
          is_week_off: data.is_week_off,
          is_gazetted_off: data.is_gazetted_off,
        };
        return obj;
      },
    );
    this.rosterDaysOff = this.employeeRoasterData[index].roster_detail
      .filter((data) => data.is_day_off || data.is_week_off)
      .map((data) => data.new_date);
    setTimeout(() => {
      $('#betweenDates').selectpicker();
      $('#betweenDates').selectpicker('refresh');
    });
  }

  rosterDayOffSubmit(): any {
    // if (!this.rosterDaysOff.length) {
    //   toastr.error('Select Days');
    //   return;
    // }
    if (!this.rosterDaysOffCheck) {
      toastr.error('Please click to agree with that');
      return;
    }
    for (
      let i = 0;
      i < this.selectedEmployeeForDayOff.roster_detail.length;
      i++
    ) {
      if (
        this.rosterDaysOff.includes(
          this.selectedEmployeeForDayOff.roster_detail[i].new_date,
        )
      ) {
        this.selectedEmployeeForDayOff.roster_detail[i].is_day_off = true;
      } else {
        this.selectedEmployeeForDayOff.roster_detail[i].is_day_off = false;
      }
    }
    $('#dayOff').modal('hide');
  }

  singleShiftModalOpen(i, j, event: Event, isClosed, employeeName): any {
    if (isClosed) {
      toastr.error(
        `Roster of ${employeeName} for the month of ${this.month} has been closed`,
      );
      return;
    }
    console.log('In');
    this.singleShiftEmployeeIndex = i;
    this.singleShiftIndex = j;
    this.selectedLocationAdd = '';
    this.selectedShiftAdd = '';
    $('#singleShiftModalClick').modal('show');
    setTimeout((e) => {
      $('.selectpicker').selectpicker('refresh');
      $('.selectpicker').selectpicker();
    }, 500);
  }

  checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;
    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0]),
    );
    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }
    return false;
  };

  addNewShiftToEmployee(): any {
    if (this.selectedShiftAdd === '') {
      toastr.error('Select Shift');
      return;
    }
    if (this.selectedLocationAdd === '') {
      toastr.error('Select Location');
      return;
    }
    const shift = this.shiftData.filter(
      (m) => m.id === Number(this.selectedShiftAdd),
    )[0];
    const location = this.locationData.filter(
      (m) => m.id === Number(this.selectedLocationAdd),
    )[0];
    let perviousShift =
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift;
    let shiftTimeArray = [];
    let dummyArray = [];
    dummyArray.push(moment(shift.time_from, ['h:mm A']).format('HH:mm'));
    dummyArray.push(moment(shift.time_to, ['h:mm A']).format('HH:mm'));
    shiftTimeArray.push(dummyArray);
    for (let i = 0; i < perviousShift.length; i++) {
      if (perviousShift[i].is_removed === false) {
        dummyArray = [];
        const prevShift = this.shiftData.filter(
          (m) => m.id === perviousShift[i].shift_id,
        )[0];
        dummyArray.push(
          moment(prevShift.time_from, ['h:mm A']).format('HH:mm'),
        );
        dummyArray.push(moment(prevShift.time_to, ['h:mm A']).format('HH:mm'));
        shiftTimeArray.push(dummyArray);
      }
    }
    if (this.checkOverlap(shiftTimeArray) === true) {
      toastr.error('Shift timing Overlap with previous');
      return;
    }
    const obj = {
      shift_name: shift.name,
      shift_id: shift.id,
      shift_text_color: shift.shift_text_color,
      shift_bg_color: shift.shift_bg_color,
      location_name: location.name,
      location_id: location.id,
      is_removed: false,
      can_delete: true,
      status: 0,
    };
    this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
      this.singleShiftIndex
    ].shift.push(obj);
    $('#singleShiftModalClick').modal('hide');
  }

  openRemoveModal(i, j, k, removeShiftId, isClosed, employeeName): any {
    this.removeSingleEmployeeShift = false;
    this.singleShiftEmployeeIndex = i;
    this.singleShiftIndex = j;
    this.removeShiftIndex = k;
    this.removeShiftId = removeShiftId;
    if (isClosed) {
      toastr.error(
        `Roster of ${employeeName} for the month of ${this.month} has been closed`,
      );
      return;
    } else {
      $('#singleShiftModalRemoveClick').modal('show');
    }
  }

  removeSingleShiftModal(): any {
    if (this.removeSingleEmployeeShift === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    $('#singleShiftModalRemoveClick').modal('hide');
    this.removeSingleEmployeeShift = false;
    if (
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift[this.removeShiftIndex].id
    ) {
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift[this.removeShiftIndex].is_removed = true;
    } else {
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
        this.singleShiftIndex
      ].shift.splice(this.removeShiftIndex, 1);
    }
  }

  removeAllSingleShiftModal(): any {
    if (this.removeSingleEmployeeShift === false) {
      toastr.error('Please click to agree with that');
      return;
    }
    $('#singleShiftModalRemoveClick').modal('hide');
    for (
      let i = 0;
      i <
      this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail
        .length;
      i++
    ) {
      for (
        let j = 0;
        j <
        this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[i]
          .shift.length;
        j++
      ) {
        if (
          this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
            i
          ].shift[j].shift_id === this.removeShiftId
        ) {
          this.employeeRoasterData[this.singleShiftEmployeeIndex].roster_detail[
            i
          ].shift[j].is_removed = true;
        }
      }
    }
  }

  getSingleEmployeeRosterDetail(): any {
    this.month = $('.month').val();
    if (this.month === '') {
      toastr.error('Select Month');
      return;
    }
    if (!this.selectedShiftAdd) {
      toastr.error('Select Shift');
      return;
    }
    if (!this.selectedLocationAdd) {
      toastr.error('Select Location');
      return;
    }
    this.ngxLoader.start();
    this.previousAddedShiftsDates = [];
    for (
      let i = 0;
      i < this.employeeRoasterData[this.applyToAllIndex].roster_detail.length;
      i++
    ) {
      for (
        let j = 0;
        j <
        this.employeeRoasterData[this.applyToAllIndex].roster_detail[i].shift
          .length;
        j++
      ) {
        if (
          this.employeeRoasterData[this.applyToAllIndex].roster_detail[i].shift[
            j
          ].shift_id === Number(this.selectedShiftAdd) &&
          this.employeeRoasterData[this.applyToAllIndex].roster_detail[i].shift[
            j
          ].location_id === Number(this.selectedLocationAdd)
        ) {
          this.previousAddedShiftsDates.push(
            this.employeeRoasterData[this.applyToAllIndex].roster_detail[i]
              .new_date,
          );
        }
      }
    }
    if (this.previousAddedShiftsDates.length) {
      $('#rosterCalendar').datepicker(
        'setDates',
        this.previousAddedShiftsDates,
      );
    } else {
      $('#rosterCalendar').datepicker('destroy');
      setTimeout(() => {
        $('#rosterCalendar').datepicker({
          inline: true,
          multidate: true,
          format: 'dd-mm-yyyy',
          orientation: 'bottom left',
          startDate: '01-' + this.month,
          showWeekDays: true,
        });
      }, 500);
    }
    // this.rosterServiceService.getSingleEmployeeRosterDetail(this.month, this.selectedLocationAdd, this.selectedShiftAdd, this.singleShiftEmployee).subscribe(data => {
    //   $('#rosterCalendar').datepicker('setDates', data);
    //   this.ngxLoader.stop();
    // }, err => {
    //   this.ngxLoader.stop();
    //   toastr.error(err.error.message);
    // });
    this.ngxLoader.stop();
  }

  singleEmployeeShiftToAllOthers(): any {
    // this.employeeRoasterData = [];
    this.showSelectAll = true;
    this.showDeselectAll = false;
    this.toEmployee = [];
    this.fromEmployee = '';
    $('#singleEmployeeShiftToAllOthers').modal('show');
    setTimeout(() => {
      $('.fromEmployee').selectpicker();
      $('.fromEmployee').selectpicker('refresh');
      $('.toEmployee').selectpicker();
      $('.toEmployee').selectpicker('refresh');
    }, 500);
  }

  submitSingleEmployeeShiftToAllOthers(): any {
    const getFromEmployeeDetail = this.employeeRoasterData.find(
      (t) => t.employee === this.fromEmployee,
    ).roster_detail;
    const getToEmployeeDetail = this.employeeRoasterData.filter((o1) => {
      return this.toEmployee.some((o2) => {
        return o1.employee === o2;
      });
    });
    for (let x = 0; x < getFromEmployeeDetail.length; x++) {
      for (let y = 0; y < getFromEmployeeDetail[x].shift.length; y++) {
        for (let i = 0; i < getToEmployeeDetail?.length; i++) {
          for (
            let j = 0;
            j < getToEmployeeDetail[i].roster_detail.length;
            j++
          ) {
            if (
              getToEmployeeDetail[i].roster_detail[j].new_date ===
              getFromEmployeeDetail[x].new_date
            ) {
              if (
                this.exists(
                  getToEmployeeDetail[i].roster_detail[j].shift,
                  getFromEmployeeDetail[x].shift[y].shift_id,
                )
              ) {
                getToEmployeeDetail[i].roster_detail[j].shift.push(
                  getFromEmployeeDetail[x].shift[y],
                );
              }
            }
          }
        }
      }
    }
    $('#singleEmployeeShiftToAllOthers').modal('hide');
  }

  getAllProduct(event): any {
    const finalEvent = event.split(' ')[0];
    if (finalEvent === '1:' && this.showSelectAll) {
      this.toEmployee = [];
      const array = [];
      this.employeeRoasterData.forEach((value, index) => {
        if (!value?.is_closed) array.push(value.employee);
      });
      this.toEmployee = array;
      setTimeout((e) => {
        $('.toEmployee').selectpicker('refresh');
      }, 500);
      this.showSelectAll = false;
      this.showDeselectAll = true;
    } else if (finalEvent === '2:' && this.showDeselectAll) {
      this.toEmployee = [];
      setTimeout((e) => {
        $('.toEmployee').selectpicker('refresh');
      }, 500);
      this.showSelectAll = true;
      this.showDeselectAll = false;
    }
  }

  openCopyRosterPopup = () => {
    $('#copyRoster').modal('show');
    $('.fromMonth').datepicker('destroy');
    $('.toMonth').datepicker('destroy');
    this.copyRosterCheck = false;
    setTimeout((e) => {
      $('#copyRosterEmployee').selectpicker('refresh');
      $('#copyRosterEmployee').selectpicker();
    }, 500);
    this.copyRosterEmployee = '';
    $('.fromMonth')
      .datepicker({
        format: 'mm-yyyy',
        viewMode: 'months',
        minViewMode: 'months',
        orientation: 'bottom left',
      })
      .on('changeDate', (e) => {
        this.fromMonth = $('.fromMonth').val();
      });
    $('.fromMonth').datepicker('setDate', this.month);
    console.log('this.month', this.month);
    $('.toMonth')
      .datepicker({
        format: 'mm-yyyy',
        viewMode: 'months',
        minViewMode: 'months',
        orientation: 'bottom left',
        endDate: this.month,
        startDate: this.month,
      })
      .on('changeDate', (e) => {
        this.toMonth = $('.toMonth').val();
      });
    $('.toMonth').datepicker('setDate', this.month);
    this.toMonth = this.month;
  };
  copyRosterSubmit = () => {
    this.fromMonth = $('.fromMonth').val();
    this.toMonth = $('.toMonth').val();
    if (!this.fromMonth || !this.toMonth) {
      toastr.error('Select Month');
      return;
    }
    if (!this.copyRosterEmployee) {
      toastr.error('Select Employee');
      return;
    }
    if (!this.copyRosterCheck) {
      toastr.error('Please click to agree with that');
      return;
    }
    this.ngxLoader.start();
    this.rosterServiceService
      .copyRosterSubmit(this.fromMonth, this.toMonth, this.copyRosterEmployee)
      .subscribe(
        (data) => {
          const employeeId = data.value[0].employee;
          const employeeData = data.value[0].roster_detail;
          var indexM;
          this.employeeRoasterData.forEach((value, index) => {
            if (Number(value.employee) === Number(employeeId)) {
              indexM = index;
            }
          });
          if (this.copyRosterCheckForReplaceOrAppend === 'replace') {
            this.employeeRoasterData[indexM] = data.value[0];
            indexM = data.value[0];
          } else {
            for (
              let i = 0;
              i < this.employeeRoasterData[indexM].roster_detail.length;
              i++
            ) {
              for (let k = 0; k < employeeData.length; k++) {
                if (!employeeData?.is_closed) {
                  if (
                    this.employeeRoasterData[indexM].roster_detail[i]
                      .new_date === employeeData[k].new_date
                  ) {
                    this.employeeRoasterData[indexM].roster_detail[
                      i
                    ].shift.push(...employeeData[k].shift);
                  }
                }
              }
            }
          }
          this.ref.markForCheck();
          $('#copyRoster').modal('hide');
          this.ngxLoader.stop();
        },
        (err) => {
          this.ngxLoader.stop();
          toastr.error(err.error.message);
        },
      );
  };
  getSystemControls = () => {
    this.ngxLoader.start();
    this.rosterServiceService.getSystemControls().subscribe(
      (data) => {
        this.copyRosterCheckForReplaceOrAppend = data.copy_roster;
        this.ngxLoader.stop();
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.error);
      },
    );
  };

  getStatusList(): any {
    this.taksServices.getStatusList().subscribe(
      (data) => {
        this.taskStatus = data;
        console.log(this.taskStatus);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getProirityList(): any {
    this.taksServices.getPriority().subscribe(
      (data) => {
        this.priority = data;
        console.log(this.priority);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getProject(): any {
    this.taksServices.getProject().subscribe(
      (data) => {
        this.project = data;
        console.log(this.taskStatus);
      },
      (err) => {
        console.log('err', err);
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }
  getEmployess(): any {
    this.taksServices.getEmployees('').subscribe(
      (data) => {
        this.employee = data;
        console.log('dataaaa', this.employee);
        setTimeout((e) => {
          $('.eselectpicker').selectpicker('refresh');
          $('.eselectpicker').selectpicker();
        }, 500);
      },
      (err) => {
        this.ngxLoader.stop();
        toastr.error(err.error.message);
      },
    );
  }

  ngOnInit(): void {
    this.getProject();
    this.getStatusList();
    this.getProirityList();
    this.getSystemControls();
    this.getScreenControls();

    $('#rosterApplyToAll').on('hide.bs.modal', () => {
      // $('#rosterCalendar').datepicker('update');
      // $('#datepicker').val('').datepicker('update');
      $('#rosterCalendar').datepicker('destroy');
      $('.checkbox-horizental input').prop('checked', false);
      $('.rosterDaysBox input').prop('checked', false);
    });
    const date = new Date();
    const previousMonth = date.setMonth(date.getMonth() - 1);
    const preMonth = new Date(previousMonth);
    console.log('previousMoth', previousMonth);
    $('.month').datepicker({
      format: 'mm-yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      orientation: 'bottom left',
      startDate: preMonth,
      // endDate: this.today,
    });
    $('.month').datepicker('setDate', this.today);
    this.month = this.today;
    this.department = '';
    // this.getEmployeesOfDepartment();

    setTimeout(() => {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      $('#fromDate').datetimepicker({
        defaultDate: yesterday,
        format: 'DD-MM-YYYY ',
        autoClose: true,
      });
      let previousDateTime = moment(yesterday).format('DD-MM-YYYY ');
      $('#fromDate').val(previousDateTime);
      $('#toDate').datetimepicker({
        defaultDate: new Date(),
        format: 'DD-MM-YYYY',
      });
      let current_time = moment().format('DD-MM-YYYY ');
      $('#toDate').val(current_time);
    }, 1000);
    $('.selectpicker').selectpicker();
    $('.eselectpicker').selectpicker();
    this.getEmployess();
  }
}
