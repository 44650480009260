<!--begin::Content-->
<div class="content d-flex flex-column flex-column-fluid" id="kt_content">
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h3 class="card-label">PM Tool Dashboard</h3>
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
    </div>
  </div>

<div class="d-flex">
<div class="col-lg-4 col-md-4 col-sm-12 col-12">
  <div class="card card-custom card-stretch gutter-b bgi-no-repeat" style="max-height: 215px; overflow-y: auto">
    <!--begin::Body-->
    <div class="card-body">
      <div class="chart-title">
        <div class="d-flex justify-content-between">
          <div>
            <h2>Live Projects</h2>
          </div>
          <div>
            <h2>{{liveProjects?.active_projects}}</h2>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-n2">
        <div style="margin-left: -10px">
            <div id="projects-Chart"></div>
        </div>
        <div>
          <h3>Total: {{liveProjects?.total_projects}}</h3>
        </div>
      </div>
      <div style="margin-top: -10px; position: absolute; margin-left: 5px">
          <p style="color: #00b56f; background-color: #c0f6e1; border-radius: 10%" class="pl-2 pr-2 pt-1 pb-1">{{(liveProjects?.active_projects / liveProjects?.total_projects) * 100 | number: "1.1-2"}}% Live</p>
      </div>
    </div>
    <!--end::Body-->
  </div>
</div>
<div class="col-lg-8 col-md-8 col-sm-12 col-12">
  <div class="card card-custom card-stretch gutter-b bgi-no-repeat" style="max-height: 215px; overflow-y: auto">
    <!--begin::Body-->
    <div class="card-body">
      <div class="hospital-grid">
        <div class="hospital-item" *ngFor="let hospital of liveProjects.medical_units">
        <span
          class="status-dot"
          [ngClass]="{ 'active': hospital.is_patient_created, 'inactive': !hospital.is_patient_created }"
        ></span>
          <span class="hospital-name">{{ hospital.title }} ({{ hospital?.id }})</span>
        </div>
      </div>
    </div>
    <!--end::Body-->
  </div>
</div>
</div>
<div class="d-flex">
  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
    <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
      <!--begin::Body-->
      <div class="card-body">
        <div class="chart-title">
          <div class="d-flex justify-content-between">
            <div>
              <h2>Today's Tickets</h2>
            </div>
            <div>
              <h2>{{todayTickets?.support?.total_tickets + todayTickets?.Dev?.total_tickets}}</h2>
            </div>
          </div>
        </div>
        <div class="charts-container">
          <div id="support-chart-container">
            <div id="today-support-chart" style="margin-left: -10px"></div>
            <div class="totals pl-10">
              <p><strong>Tasks: {{todayTickets?.support?.total_tickets}}</strong></p>
              <p><strong>Resolved: {{todayTickets?.support?.resolved_tickets}}</strong></p>
            </div>
          </div>

          <div id="dev-chart-container">
            <div id="today-dev-chart"></div>
            <div class="totals pl-12">
              <p><strong>Tasks: {{todayTickets?.Dev?.total_tickets}}</strong></p>
              <p><strong>Resolved: {{todayTickets?.Dev?.resolved_tickets}}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
    <div class="card card-custom card-stretch gutter-b bgi-no-repeat">
      <!--begin::Body-->
      <div class="card-body">
        <div class="chart-title">
          <div class="d-flex justify-content-between">
            <div>
              <h2>Live Tickets</h2>
            </div>
            <div>
              <h2>{{liveTickets?.support?.total_tickets + liveTickets?.Dev?.total_tickets}}</h2>
            </div>
          </div>
        </div>
        <div class="charts-container">
          <div id="live-support-chart-container">
            <div id="live-support-chart" style="margin-left: -10px"></div>
            <div class="totals pl-10">
              <p><strong>Tasks: {{liveTickets?.support?.total_tickets}}</strong></p>
              <p><strong>Resolved: {{liveTickets?.support?.resolved_tickets}}</strong></p>
            </div>
          </div>

          <div id="live-dev-chart-container">
            <div id="live-dev-chart"></div>
            <div class="totals pl-12">
              <p><strong>Tasks: {{liveTickets?.Dev?.total_tickets}}</strong></p>
              <p><strong>Resolved: {{liveTickets?.Dev?.resolved_tickets}}</strong></p>
            </div>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </div>
  </div>
</div>

<div class="d-flex">
  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
    <div class="card card-custom card-stretch gutter-b bgi-no-repeat" style="max-height: 300px; overflow-y: auto">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th style="width: 50%"></th>
            <th style="width: 25%" class="text-center">Assigned</th>
            <th style="width: 25%" class="text-center">Resolved</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let employee of employeeListData">
            <tr>
              <td>{{employee?.first_name + ' ' +employee?.last_name}} ({{employee?.dept_title}})</td>
              <td class="text-center">{{employee?.assigned_task}}</td>
              <td class="text-center">{{employee?.resolved_task}}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
    <div class="card card-custom card-stretch gutter-b bgi-no-repeat" style="max-height: 300px; overflow-y: auto">
      <div class="card-body">
        <table class="table">
          <thead>
          <tr>
            <th style="width: 50%"></th>
            <th style="width: 25%" class="text-center">Assigned</th>
            <th style="width: 25%" class="text-center">Resolved</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let employee of todayEmployeeListData">
            <tr>
              <td>{{employee?.first_name + ' ' +employee?.last_name}} ({{employee?.dept_title}})</td>
              <td class="text-center">{{employee?.assigned_task}}</td>
              <td class="text-center">{{employee?.resolved_task}}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

</div>
