<!--begin::Content-->
<div
  class="content d-flex flex-column flex-column-fluid"
  id="kt_content"
  xmlns="http://www.w3.org/1999/html"
>
  <!--begin::Subheader-->
  <div class="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div
      class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
    >
      <!--begin::Info-->
      <div class="d-flex align-items-center flex-wrap mr-1">
        <!--begin::Page Heading-->
        <div class="d-flex align-items-baseline flex-wrap mr-5">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-1 mr-5">{{ screenName }}</h5>

          <ul class="nav nav-pills nav-light-primary nav-bold border-0">
            <li class="nav-item">
              <a
                class="nav-link border-0 active"
                data-toggle="tab"
                href="#personalInformation"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">{{ dataValues.patient_heading }}</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="toggleTabs('zakat')">
              <a class="nav-link border-0" data-toggle="tab" href="#zakat">
                <!--                <span class="nav-icon"><i class="flaticon2-chat-1"></i></span>-->
                <span class="nav-text">Eligible for Zakat</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="familyCall()">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#family_info"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Family Information</span>
              </a>
            </li>
            <li class="nav-item" *ngIf="patient" (click)="documentsCall()">
              <a class="nav-link border-0" data-toggle="tab" href="#documents">
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Documents</span>
              </a>
            </li>

            <li class="nav-item" (click)="billingCall()" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#billing">
                <!--                <span class="nav-icon"><i class="flaticon2-hospital"></i></span>-->
                <span class="nav-text">Billing</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient" (click)="appointmentsCall()">
              <a
                class="nav-link border-0"
                data-toggle="tab"
                href="#appointments"
              >
                <!--                <span class="nav-icon"><i class="flaticon2-analytics-1"></i></span>-->
                <span class="nav-text">Appointments</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#clinical">
                <!--                <span class="nav-icon"><i class="flaticon2-schedule"></i></span>-->
                <span class="nav-text">Clinical</span>
              </a>
            </li>

            <li class="nav-item" *ngIf="patient">
              <a class="nav-link border-0" data-toggle="tab" href="#reports">
                <!--                <span class="nav-icon"><i class="fas fa-bed"></i></span>-->
                <span class="nav-text">Reports</span>
              </a>
            </li>
          </ul>
          <!--end::Page Title-->
        </div>
        <!--end::Page Heading-->
      </div>
      <!--end::Info-->
      <!--begin::Toolbar-->
      <div class="d-flex align-items-center">
        <div class="d-block d-sm-block d-md-none">
          <a
            class="btn btn-light-primary font-weight-bolder btn-sm"
            (click)="openPatientList()"
          >Patients</a
          >
        </div>
      </div>
      <!--end::Toolbar-->
    </div>
  </div>
  <!--end::Subheader-->

  <!--begin::Entry-->
  <div class="d-flex flex-column-fluid main-content">
    <!--begin::Container-->
    <!--    container-xl container-xxl-->
    <div class="container-fluid">
      <!--begin:: Registration-->
      <div class="tab-content">
        <div
          class="tab-pane fade show active"
          id="personalInformation"
          role="tabpanel"
          aria-labelledby="personalInformation"
        >
          <div class="row">
            <div class="col-md-9 col-sm-12 col-12 p-lg-0 p-md-0">
              <form
                [formGroup]="PatientRegistrationForm"
                (ngSubmit)="newRegistration()"
              >
                <div class="row mb-3">
                  <div
                    class="col-lg-2 col-md-3 col-sm-12 col-12 pr-0 mb-4 mb-sm-4 mb-md-0"
                  >
                    <div class="card h-100">
                      <div class="card-body patient-img-card">
                        <div class="row justify-content-center">
                          <!--Image Upload-->
                          <div
                            class="image-input image-input-empty image-input-outline mb-4"
                            id="kt_image_5"
                            style="position: relative"
                          >
                            <img
                              *ngIf="
                                patient?.profile_image;
                                else profileImageElse
                              "
                              [src]="patient.profile_image"
                              class="patient_img"
                              style="
                                object-fit: contain;
                                position: absolute;
                                padding: 5px;
                                width: 100%;
                                height: 100%;
                              "
                            />
                            <ng-template #profileImageElse>
                              <img
                                [src]="
                                  patientImage
                                    ? patientImage
                                    : '../../../../../assets/media/users/blank.png'
                                "
                                class="patient_img"
                                style="
                                  object-fit: contain;
                                  position: absolute;
                                  padding: 5px;
                                  width: 100%;
                                  height: 100%;
                                "
                              />
                            </ng-template>

                            <div class="image-input-wrapper"></div>

                            <!--                            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">-->
                            <!--                              <i class="fa fa-pen icon-sm text-muted"></i>-->
                            <!--                              <input type="file" name="profile_avatar" (change)="fileUploadFunc($event)" accept=".png, .jpg, .jpeg"/>-->
                            <!--                              <input type="hidden" name="profile_avatar_remove"/>-->
                            <!--                            </label>-->
                          </div>
                        </div>

                        <div class="row text-center">
                          <div class="col-12">
                            <button
                              class="btn btn-primary btn-sm mb-2 button-width"
                              type="button"
                              (click)="cambox()"
                            >
                              {{ dataValues.picture }}
                            </button>
                            <br />
                            <button
                              class="btn btn-primary btn-sm button-width"
                              type="button"
                              (click)="getThumbImpression()"
                            >
                              {{ dataValues.impression }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10 col-md-9 col-sm-12 col-12">
                    <div class="card">
                      <div class="card-body patient-img-card">
                        <div class="row mb-0 mx-0">
                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>{{ dataValues.title }}</label>
                              <select
                                class="form-control form-control-sm normal-height p-lr-0"
                                formControlName="title"
                                [ngClass]="{
                                  'is-invalid': submitted && f.title.errors
                                }"
                                (change)="onChange()"
                              >
                                <option value="mr">Mr</option>
                                <option value="mrs">Mrs</option>
                                <option value="miss">Miss</option>
                                <option value="bo">B/O</option>
                                <option value="do">D/O</option>
                                <option value="dr">Dr</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label
                              >{{ dataValues.first_name }}
                                <span class="text-danger">*</span></label
                              >
                              <input
                                type="text"
                                autofocus
                                [value]="
                                  PatientRegistrationForm.value.first_name
                                    | titlecase
                                "
                                formControlName="first_name"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.first_name }}"
                                [ngClass]="{
                                  'is-invalid': submitted && f.first_name.errors
                                }"
                                (keypress)="_keyPressOnlyChar($event)"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>{{ dataValues.last_name }}</label>
                              <input
                                type="text"
                                [value]="
                                  PatientRegistrationForm.value.last_name
                                    | titlecase
                                "
                                formControlName="last_name"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.last_name }}"
                                (keypress)="_keyPressOnlyChar($event)"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.number }}
                                <span class="text-danger">*</span></label
                              >
                              <input
                                type="text"
                                formControlName="phone1"
                                id="phone1"
                                maxlength="11"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.number }}"
                                (keypress)="_onlyNumeric($event)"
                                (focusout)="searchPatientByPhoneNumber()"
                                [ngClass]="{
                                  'is-invalid': submitted && f.phone1.errors
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.cnic }}
                                <span *ngIf="cnicMandatory" class="text-danger"
                              >*</span
                              >
                              </label>
                              <input
                                type="text"
                                formControlName="patient_nic"
                                id="nic"
                                class="form-control form-control-sm"
                                placeholder="{{ dataValues.cnic }}"
                                (keypress)="_onlyNumeric($event)"
                                maxlength="13"
                                [ngClass]="{
                                  'is-invalid':
                                    submitted && f.patient_nic.errors
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.gender }}
                                <span class="text-danger">*</span></label
                              >
                              <select
                                class="form-control form-control-sm"
                                formControlName="gender"
                                [ngClass]="{
                                  'is-invalid': submitted && f.gender.errors
                                }"
                              >
                                <option value="" [disabled]="true">
                                  {{ dataValues.select }}
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="unidentified">
                                  Unidentified
                                </option>
                                <option value="transgender">Transgender</option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div style="display: flex !important">
                              <div class="">
                                <label>
                                  {{ dataValues.age }}
                                  <span class="text-danger">*</span></label
                                >
                                <div class="d-flex">
                                  <div class="col-6 pl-0 pr-0">
                                    <input
                                      type="text"
                                      min="0"
                                      max="150"
                                      placeholder="{{ dataValues.age }}"
                                      formControlName="pat_age_value"
                                      class="form-control form-control-sm input-transparent p-r-0"
                                      (keypress)="checkAge($event); _onlyNumeric($event)"
                                      (keyup)="checkAge($event)"
                                      [ngClass]="{
                                    'is-invalid':
                                      submitted && f.pat_age_value.errors
                                  }"
                                    />
                                  </div>
                                  <div class="col-6 pr-0">
                                    <select
                                      style="text-transform: capitalize"
                                      class="form-control form-control-sm"
                                      formControlName="pat_age_type"
                                      [value]="selected_age_type || 'years'"
                                    >
                                      <option
                                        [value]="age_type"
                                        *ngFor="let age_type of age_types"
                                      >
                                        {{ age_type }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group"
                                 [ngClass]="{
                              'is-invalid':
                                submitted && f.patient_type_id.errors
                            }">
                              <label> {{ dataValues.patient_type }} </label>
                              <select
                                class="form-control form-control-sm"
                                formControlName="patient_type_id"
                                [ngModel]="private_patient_type_id"
                                (change)="onChangetype($event.target.value)"
                                id="selected_type"
                              >
                                <option value="" [disabled]="true">
                                  {{ dataValues.select }}
                                </option>
                                <option
                                  [value]="patient_type.id"
                                  *ngFor="let patient_type of patient_types"
                                >
                                  {{ patient_type.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                              <label>
                                {{ dataValues.city }}
                                </label
                              >
                              <div
                              >
                                <select
                                  class="form-control form-control-sm city bootstrap-select-container"
                                  formControlName="city_id"
                                  data-container="body"
                                  data-live-search="true"
                                >
                                  <option value="" [disabled]="true">
                                    {{ dataValues.select }}
                                  </option>
                                  <ng-container *ngFor="let city of cityList">
                                    <option [value]="city.id">
                                      {{ city.name }}
                                    </option>
                                  </ng-container>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-8 col-sm-10 col-12">
                            <div class="form-group">
                              <label> {{ dataValues.address }} </label>
                              <input
                                type="text"
                                formControlName="address1"
                                class="form-control form-control-sm"
                                autocomplete="off"
                                placeholder="{{ dataValues.address }}"
                              />
                            </div>
                          </div>

                          <div class="col-12">
                            <div class="form-group m-0">
                              <div class="checkbox-inline">
                                <label class="checkbox">
                                  <input
                                    type="checkbox"
                                    class="guardian filter"
                                    formControlName="is_zakat_eligible"
                                  />
                                  <span class="mr-2"></span
                                  >Eligible for Zakat?
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-body patient-card">
                    <h5>Guardian Information</h5>
                    <div class="row">

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.guardian_name }} </label>
                          <input
                            type="text"
                            formControlName="guardian_first_name"
                            placeholder="{{ dataValues.guardian_name }}"
                            class="form-control-sm form-control input-transparent"
                            (keypress)="_keyPressOnlyChar($event)"
                          />
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label>{{ dataValues.relation }} </label>
                          <select
                            class="form-control form-control-sm"
                            formControlName="guardian_relationship"
                            [ngClass]="{
                              'is-invalid':
                                submitted && f.guardian_relationship.errors
                            }"
                          >
                            <option value="">
                              {{ dataValues.select }}
                            </option>
                            <ng-container *ngFor="let lov of lookups">
                              <option
                                [value]="lov.key"
                                *ngIf="lov.category == 'relationship'"
                              >
                                {{ lov.key }}
                              </option>
                            </ng-container>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.cnic }} </label>
                          <input
                            type="text"
                            formControlName="guardian_nic"
                            id="guardian_nic"
                            placeholder="{{ dataValues.guardian_cnic }}"
                            class="form-control-sm form-control input-transparent"
                            (keypress)="_onlyNumeric($event)"
                            maxlength="13"
                          />
                        </div>
                      </div>
                      <div class="col-md-3 col-sm-6 col-12">
                        <div class="form-group">
                          <label> {{ dataValues.contact }} </label>
                          <input
                            type="text"
                            formControlName="guardian_phone"
                            placeholder="{{ dataValues.contact }}"
                            class="form-control-sm form-control"
                            [ngClass]="{
                              'is-invalid': submitted && f.guardian_phone.errors
                            }"
                            (keypress)="_onlyNumeric($event)"
                            maxlength="11"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-6">
                  <div class="col-12 text-right"> <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      (click)="addServices(patient)"
                      accesskey="z"
                      [disabled]="!patient || isPatientDeceased"
                    >
                      Invoicing
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                      accesskey="u"
                      (click)="updatePatient()"
                      *ngIf="isShowUpDateButton === true"
                    >
                      Update
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      (click)="clearFormConfirm()"
                      accesskey="c"
                    >
                      Refresh
                    </button>
                    <button
                      type="submit"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      accesskey="r"
                      [disabled]="patient || isDisable"
                    >
                      Save
                    </button>

                    <!--              <button type="button" class="btn btn-primary">Clear</button>-->
                    <!--              <button type="submit" class="btn btn-primary ml-3">Submit</button>-->
                  </div>
                  <div class="col-12 text-right mt-2">
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                      accesskey="d"
                      (click)="discountPage(patient, 'patient/adjustments-v2')"
                      *ngIf="showAdjustmentButton"
                    >
                      Adjustments
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient || isPatientDeceased"
                      accesskey="a"
                      (click)="admissionPage(patient, 'patient/admission-v2')"
                    >
                      Admission
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                    >
                      <a
                        (click)="printCard()"
                        class="text-white a-hover"
                        accesskey="h"
                      >Health Card</a
                      >
                    </button>
                    <button
                      type="button"
                      class="btn btn-sm btn-primary ml-2 button-width"
                      [disabled]="!patient"
                    >
                      <a
                        (click)="print('print_wrist_band')"
                        class="text-white a-hover"
                        accesskey="w"
                      >Wrist Band</a
                      >
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="col-md-3 col-sm-12 col-12 mt-10 mt-sm-10 mt-md-0 mt-lg-0 d-none d-sm-none d-md-flex"
            >
              <div class="card clearfix">
                <div class="card-body patient-card-listing">
                  <form
                    [formGroup]="PatientSearchForm"
                    (ngSubmit)="fetchPatients()"
                  >
                    <div class="row">
                      <div class="col-12 mb-4">
                        <select
                          class="form-control form-control-sm"
                          formControlName="type"
                          [(ngModel)]="PatientSearchForm.value.type"
                          (change)="checkPatientType()"
                        >
                          <option value="default">{{ dataValues.all }}</option>
                          <option value="mrn">MRN</option>
                          <option value="name" *ngIf="isShowFilterName">
                            {{ dataValues.name }}
                          </option>
                          <option value="phone1">{{ dataValues.phone }}</option>
                          <option value="patient_nic">
                            {{ dataValues.cnic }}
                          </option>
                          <option value="sap_id">SAP ID</option>
                          <option value="thumb">THUMB</option>
                        </select>
                      </div>

                      <div class="col-12 p-lr-0 button-wrapper">
                        <input
                          type="text"
                          formControlName="search_query"
                          id="patient_search_here"
                          placeholder="Search patient here"
                          class="form-control form-control-sm input-transparent"
                        />
                        <button class="btn btn-primary btn-sm" type="submit">
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                  <div class="mt-3 patient-listing">
                    <!--                *ngFor="let requests of allOperationRequests"-->
                    <ng-container
                      *ngFor="let patient of patients; let i = index"
                    >
                      <!--begin::Item-->
                      <!--                  align-items-center-->
                      <div
                        class="d-flex py-3 patient-panel"
                        (click)="fetchPatientInfo(patient, i, true)"
                      >
                        <!--begin::Symbol-->
                        <div class="symbol symbol-40 symbol-light-success mr-5">
                          <span class="symbol-label">
                            <img
                              style="border-radius: 12%; width: 40px"
                              *ngIf="
                                patient?.profile_image !== '';
                                else profileImageNot
                              "
                              src="{{ patient?.profile_image }}"
                              class="h-100 align-self-end"
                              alt=""
                            />
                            <ng-template #profileImageNot>
                              <img
                                *ngIf="patient?.gender === 'transgender'"
                                src="assets/media/users/blank.png"
                                class="h-100 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'unidentified'"
                                src="assets/media/users/blank.png"
                                class="h-100 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'male'"
                                src="assets/media/svg/avatars/009-boy-4.svg"
                                class="h-75 align-self-end"
                                alt=""
                              />
                              <img
                                *ngIf="patient?.gender === 'female'"
                                src="assets/media/svg/avatars/002-girl.svg"
                                class="h-75 align-self-end"
                                alt=""
                              />
                            </ng-template>
                          </span>
                        </div>
                        <!--end::Symbol-->
                        <!--begin::Text-->
                        <!--            flex-grow-1-->
                        <div
                          class="d-flex flex-column flex-grow-1 font-weight-bold"
                        >
                          <!--              text-hover-primary-->
                          <a class="text-dark mb-1 font-size-lg">{{
                              patient?.first_name + " " + patient?.last_name
                            }}</a>
                          <div class="d-inline">
                            <span class="text-muted mr-4">{{
                                patient?.mrn
                              }}</span>
                            <span class="text-muted mr-4">{{
                                patient?.patient_type?.name | titlecase
                              }}</span>
                          </div>
                        </div>
                        <!--end::Text-->
                      </div>
                      <!--end::Item-->
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="billing"
          role="tabpanel"
          aria-labelledby="billing"
          *ngIf="billingTab === true"
        >
          <app-services-history [patient]="patient"></app-services-history>
        </div>
        <div
          class="tab-pane fade"
          id="zakat"
          role="tabpanel"
          aria-labelledby="zakat"
          *ngIf="zakatEligibility"
        >
          <app-zakat [patient]="patient"></app-zakat>
        </div>
        <div
          class="tab-pane fade"
          id="family_info"
          role="tabpanel"
          aria-labelledby="family_info"
          *ngIf="familyTab"
        >
          <app-family-information [patient]="patient"></app-family-information>
        </div>
        <div
          class="tab-pane fade"
          id="documents"
          role="tabpanel"
          aria-labelledby="family_info"
          *ngIf="documentsTab"
        >
          <app-patient-documents
            [patientData]="patient"
          ></app-patient-documents>
        </div>
        <div
          class="tab-pane fade"
          id="appointments"
          role="tabpanel"
          aria-labelledby="appointments"
          *ngIf="appointmentsTab"
        >
          <app-booking-slot-appointments
            [patient]="patient"
          ></app-booking-slot-appointments>
        </div>

        <div
          class="tab-pane fade"
          id="clinical"
          role="tabpanel"
          aria-labelledby="clinical"
        >
          clinical
        </div>

        <div
          class="tab-pane fade"
          id="reports"
          role="tabpanel"
          aria-labelledby="reports"
        >
          reports
        </div>
      </div>

      <!--end::Ot Registration-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Entry-->
</div>
<!--end::Content-->

<!--begin::Quick Cart-->
<div
  id="patient-list"
  class="offcanvas offcanvas-right p-5 d-block d-sm-block d-md-none"
>
  <!--begin::Header-->
  <div
    class="offcanvas-header d-flex align-items-center justify-content-between pb-7"
  >
    <h4 class="font-weight-bold m-0">Patient List</h4>
    <button
      class="btn btn-xs btn-icon btn-light btn-hover-primary"
      (click)="closePatientList()"
    >
      <i class="ki ki-close icon-xs text-muted"></i>
    </button>
  </div>
  <!--end::Header-->
  <!--begin::Content-->
  <div class="offcanvas-content">
    <!--begin::Wrapper-->
    <div class="offcanvas-wrapper mb-5 scroll-pull">
      <form [formGroup]="PatientSearchForm" (ngSubmit)="fetchPatients()">
        <div class="row">
          <div class="col-12 mb-4">
            <select
              class="form-control form-control-sm"
              formControlName="type"
              [(ngModel)]="PatientSearchForm.value.type"
            >
              <option value="default">All</option>
              <option value="mrn">MRN</option>
              <option value="name">Name</option>
              <option value="phone1">Phone</option>
              <option value="patient_nic">CNIC</option>
              <option value="sap_id">SAP ID</option>
            </select>
          </div>

          <div class="col-12 p-lr-0 button-wrapper">
            <input
              type="text"
              formControlName="search_query"
              placeholder="Search patient here"
              class="form-control form-control-sm input-transparent"
            />
            <button class="btn btn-primary btn-sm" type="submit">Search</button>
          </div>
        </div>
      </form>

      <div class="mt-8 patient-sidebar-listing">
        <ng-container *ngFor="let patient of patients; let i = index">
          <!--begin::Item-->
          <div
            class="d-flex py-3 patient-panel"
            (click)="fetchPatientInfo(patient, i, true)"
          >
            <!--begin::Symbol-->
            <div class="symbol symbol-40 symbol-light-success mr-5">
              <span class="symbol-label">
                <img
                  *ngIf="patient?.profile_image !== ''; else profileImageNot"
                  src="{{ patient?.profile_image }}"
                  class="h-100 align-self-end"
                  alt=""
                />
                <ng-template #profileImageNot>
                  <img
                    *ngIf="patient?.gender === 'transgender'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'unidentified'"
                    src="assets/media/users/blank.png"
                    class="h-100 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'male'"
                    src="assets/media/svg/avatars/009-boy-4.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                  <img
                    *ngIf="patient?.gender === 'female'"
                    src="assets/media/svg/avatars/002-girl.svg"
                    class="h-75 align-self-end"
                    alt=""
                  />
                </ng-template>
              </span>
            </div>
            <!--end::Symbol-->
            <!--begin::Text-->
            <!--            flex-grow-1-->
            <div class="d-flex flex-column flex-grow-1 font-weight-bold">
              <!--              text-hover-primary-->
              <a class="text-dark mb-1 font-size-lg">{{
                  patient?.first_name + " " + patient?.last_name
                }}</a>
              <div class="d-inline">
                <span class="text-muted mr-4">{{ patient?.mrn }}</span>
                <!--                        / {{patient?.gender | titlecase}}-->
                <span class="text-muted mr-4">{{ patient?.age }}</span>
              </div>
              <span class="text-muted mr-4">{{
                  patient?.patient_type?.name | titlecase
                }}</span>
            </div>

            <!--end::Text-->
          </div>
          <!--end::Item-->
        </ng-container>
      </div>
    </div>
    <!--end::Wrapper-->
    <!--begin::Purchase-->
    <div class="offcanvas-footer">
    </div>
    <!--end::Purchase-->
  </div>
  <!--end::Content-->
</div>
<!--end::Quick Cart-->

<!--New Register Patient Modal-->
<div class="example-preview">
  <div>
    <ng-template #newPatientModal let-c="close" let-d="dismiss">
      <form>
        <div class="modal-header p-3">
          <h4 class="modal-title">Add Services</h4>
          <a class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div>{{ message }}<br /></div>
        </div>
        <div class="modal-footer p-3">
          <a class="btn btn-sm btn-primary" (click)="c('Close click')">Close</a>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (submit)="addServices(patient)"
            (click)="addServices(patient)"
          >
            Add Service
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Confirm pateint Admission Modal-->
<div class="example-preview">
  <div>
    <ng-template #conformPatientAdmissionModal let-c="close" let-d="dismiss">
      <form>
        <div class="modal-header p-3">
          <h4 class="modal-title">Confirmation</h4>
          <a
            class="close cursor-pointer"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </a>
        </div>
        <div class="modal-body">
          <div>
            Patient already admitted. Are you sure you sure you want to
            proceed?<br />
          </div>
        </div>
        <div class="modal-footer p-3">
          <a class="btn btn-sm btn-primary" (click)="c('Close click')">Close</a>
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            (submit)="admissionPageConform(patient, 'patient/admission-v2')"
            (click)="admissionPageConform(patient, 'patient/admission-v2')"
          >
            Confirm
          </button>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Clear Confirmation Modal-->
<div class="example-preview">
  <div>
    <ng-template #clearConfirmModal let-c="close" let-d="dismiss">
      <form>
        <div class="">
          <div class="modal-header p-3">
            <h4 class="modal-title">Confirmation</h4>
            <a
              class="close cursor-pointer"
              aria-label="Close"
              (click)="d('Cross click')"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div>Are you sure you sure you want to clear the fields?</div>
          </div>
          <div class="modal-footer p-3">
            <a class="btn btn-sm btn-light-primary" (click)="d('Cross click')"
            >Close</a
            >
            <button
              type="submit"
              class="btn btn-sm btn-light-primary"
              (submit)="resetPatientRegistrationForm()"
              (click)="resetPatientRegistrationForm()"
            >
              Confirm
            </button>
          </div>
        </div>
      </form>
    </ng-template>
  </div>
</div>

<!--Image Modal-->
<div class="example-preview">
  <div>
    <ng-template #imageModal let-c="close" let-d="dismiss">
      <div class="">
        <div class="modal-header p-3">
          <h4 class="modal-title">Capturing the Image!</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="d('Cross click')"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body text-center">
          <div>
            <div *ngIf="showImage === false">
              <webcam
                [height]="199.99999999999997"
                [width]="266.66666666666663"
                [trigger]="triggerObservable"
                (imageCapture)="handleImage($event)"
                *ngIf="showWebcam"
                [allowCameraSwitch]="allowCameraSwitch"
                [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions"
                [imageQuality]="1"
                (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"
              ></webcam>
            </div>
            <img
              [src]="webcamImage.imageAsDataUrl"
              style="width: 266px; height: 200px"
              class=""
              *ngIf="showImage === true"
              alt=""
            />
          </div>
        </div>
        <div class="modal-footer p-3">
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === false"
            (click)="triggerSnapshot()"
          >
            Take A Image
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="resetImageBox()"
          >
            Reset
          </button>
          <button
            class="btn btn-sm btn-primary"
            *ngIf="showImage === true"
            (click)="captureImageComplete()"
          >
            Done
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  class="modal fade"
  id="patientFoundAgainstNumberModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="staticBackdrop"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <form (ngSubmit)="patientFoundAgainstNumber()">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Alert!</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>Patient is already register against this phone number</p>
        </div>
        <div class="modal-footer">
          <a
            type="button"
            class="btn btn-light-primary font-weight-bold"
            data-dismiss="modal"
          >Close</a
          >
          <button
            type="button"
            class="btn btn-primary font-weight-bold"
            id="patientFoundAgainstNumberBtn"
            (submit)="patientFoundAgainstNumber()"
            (click)="patientFoundAgainstNumber()"
          >
            See Patient
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

